.custom-modal {
  border-radius: 8px;
  overflow: hidden;

  .ant-modal-content {
    border-radius: 8px;
    .ant-modal-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding-bottom: 12px;
      height: 48px;
    }
    .ant-modal-body {
      padding: 24px;
    }
  }
  .ant-modal-body {
    padding: unset !important;
  }
  &.modal-notify-body {
    .ant-modal-body {
      padding: 40px 24px 32px 24px !important;
    }
  }
  .ant-modal-footer {
    background: #fafafa;
    box-shadow: 0px -1px 0px #eff2f3;
    border-radius: 0px 0px 8px 8px;
    padding: 18px 18px 16px 18px;
    border-top: none;
  }
  .ant-modal-close {
    .ant-modal-close-x {
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
    }
  }
}
