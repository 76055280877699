.custom-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  span:not([class]) {
    font-size: 14px;
  }
  &::after {
    display: none;
  }
  &:hover {
  }
  &:disabled {
    background: #f5f5f5 !important;
    border: 1px solid #f0f0f0 !important;
  }
  &.ant-btn-link {
    padding: unset;
  }
  &.main {
  }

  &.secondary {
  }
  &.cyan {
    color: white !important;
  }
  &.cyan-link {
  }
  &.success {
    background: #237804 !important;
    color: white !important;
  }
  &.warning {
    background: #faad14 !important;
    color: black !important;
  }
  &.danger {
    background: #f5222d !important;
    color: white !important;
  }
  &.view {
    // border-color: transparent;
    // background-color: #f5f5f5 !important;
    color: rgba(0, 0, 0, 0.85) !important;
    &:active,
    &:focus {
    }
    &.cyan {
      &:active,
      &:focus {
      }
    }
  }
  &.edit {
    // background-color: #e0efee !important;
    // border-color: transparent;
  }
  &.remove {
    // border-color: transparent;
    // background-color: #fff1f0 !important;
    color: #f5222d !important;
    &:focus {
      border-color: #fff1f0 !important;
    }
  }
}

.ant-btn > .anticon {
  line-height: 0;
}
