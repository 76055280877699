.ant-popover-arrow {
    display: none !important;
}

.notifications .ant-popover-inner-content {
    padding: 0px 0px !important;
}

.notification-item {
    box-shadow: 0px 1px 0px #EFF2F3;
}

.notifications .ant-list-empty-text {
    height: 443px;
}