.customNotify {
  padding: 10px 16px;
  border-radius: 2px;

  &.success {
    background: #f6ffed;
    border: 1px solid #b7eb8f;
  }
  &.error {
    background: #fff1f0;
    border: 1px solid #ffccc7;
  }
  .ant-notification-notice-close {
    top: 10px;
  }
}
